import { Button, Modal } from "react-bootstrap";

export default function CustomModal({ showModal, handleClose }) {
  return (
    <Modal
      show={showModal?.is}
      onHide={handleClose}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <>
          {showModal?.title && (
            <div className="card-header text-center justify-content-center">
              <h3 className="text-success">{showModal?.title}</h3>
            </div>
          )}
          <div className="card-body py-5">
            <h5 className="text-center justify-content-center text-muted">
              {showModal?.msg}
            </h5>
          </div>
          <div className="d-grid gap-5 d-md-flex justify-content-md-end">
            <button
              className="btn text-success  btn-block fs-6 fw-bold "
              onClick={() => handleClose()}
            >
              Close
            </button>
          </div>
        </>
      </Modal.Body>
    </Modal>
  );
}
