import React, { useState } from "react";
import "./style.css";
import { NavLink } from "react-router-dom";

const containerStyle = {
  backgroundImage: 'url("/images/videoOverlay.jpg")',
  backgroundSize: "cover",
  backgroundPosition: "center",
  position: "relative",
};
const overlayStyle = {
  position: "absolute",
  // top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(15, 14, 14, 0.7)",
};
export default function CheckOutPlatform() {
  const [link] = useState(
    "https://www.loom.com/embed/abc73ae802cc4f9cb13a68125803af21?sid=abd7b517-4ceb-4e9f-9e85-eb8e825c32a8"
  );

  return (
    <div style={containerStyle}>
      <div style={overlayStyle}></div>
      <div className="container">
        <div className="row py-5 justify-content-center h-50 ">
          <div
            className="col-sm-12, col-md-8  align-items-center firstcol mx-auto"
            style={{ zIndex: 1 }}
          >
            <div className="py-5 px-2">
              <h1 className="text-center" style={{ color: "#fff" }}>
                Check out the Platform!{" "}
              </h1>
              <p
                className="content text-center pt-2 pb-5 fs-4"
                style={{ color: "rgba(255, 255, 255, 0.85)" }}
              >
                Take a peek behind the curtain
              </p>

              <div className="card h-100" style={{ marginTop: "15px" }}>
                <div className="card-body">
                  {" "}
                  <div
                    className="d-flex justify-content-center d-none d-md-block"
                    style={{ height: "60vh" }}
                  >
                    <iframe
                      src={link}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                      style={{
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </div>
                  <div
                    className="d-flex justify-content-center d-block d-sm-block d-md-none"
                    style={{ height: "30vh" }}
                  >
                    <iframe
                      src={link}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                      style={{
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 d-flex  justify-content-center">
            <NavLink
              className="btn text-white btn-success fs-4 fw-bold px-5 mt-5 "
              to="Signup"
              style={{
                width: "fit-content",
                zIndex: "1",
              }}
            >
              Scale Your Portfolio
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}
