import React, { useState } from "react";
import "./style.css";
import { Card, Modal } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { NavLink } from "react-router-dom";

export default function QuoteCards() {
  const isSmallScreen = window.matchMedia("(max-width: 576px)").matches;
  const isMediumScreen = window.matchMedia("(max-width: 992px)").matches;

  const [show, setShow] = useState({ isOpen: false, item: false });

  const data = [
    {
      title: `“One of the most intimidating things about selling a property with seller-financing was figuring out how to take payments and keep up with the loan balances, late payments, etc. Your Land Loans provided everything I needed to start selling my properties with seller-financing and the interface and process couldn't be easier. It's been an indispensable tool for my land business”`,
      fName: "Joe, New Life Lands",
      sName: "J",
    },
    {
      title: [
        `"I've used 3 different platforms to service my land notes, and we have 500 of them! There's no better platform out there than YourLandLoans. The dashboard is absolutely amazing! FINALLY- someone created a dashboard that helps me understand all the stats about my loans.`,
        <br />,
        `Not only that, but service is top notch. JT and his team are constantly iterating and improving the platform. You know that you're in good hands as a fellow active land investor wants to create an amazing experience for you and your customers, treating you the way that he would want to be treated. It's the best out note servicing product out there and the best is yet to come!"`,
      ],
      attachedVideo: [
        <div style={{ padding: "60% 0 0 0", position: "relative" }}>
          <iframe
            // https://vimeo.com/1016261282/bde05c9046?share=copy
            src="https://player.vimeo.com/video/1016261282?h=bde05c9046&badge=0&autopause=0&player_id=0"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            title="Dave Denniston- Testimonial for Your Land Loans"
          />
        </div>,
      ],
      fName: "Dave, Generation Family Land",
      sName: "D",
    },
    {
      title: `“YLL is exactly the tool I've been searching for since
      I started my land business back in 2017. It has a ton
      of investor-friendly features that really simplify the
      seller financing process. Definitely in a class of its
      own! And my bookkeeper loves it too!”`,

      fName: "Bryan, The Terrain Project",
      sName: "B",
    },
    {
      title: `“We are so impressed with YLL! From the beautiful
      interface to the ease of use. We are adding all of our
      note buyers as quickly as we can!”`,

      fName: "Amy, Legacy Land Company",
      sName: "A",
    },
    {
      title: ` “Your Land Loans is designed by a land investor for
      land investors, and it shows. Setting up new loans is
      quick and the user interface is intuitive and easy to
      use. It as an overall professional appearance which
      represents your business well”`,

      fName: "Brian, Land on the Horizon",
      sName: "B",
    },
    {
      title: `“Transferring my notes to YLL was a no-brainer. It saved me massive amounts of opaque fees from payment processors and also costs less than competitors - all for a much better product that’s getting better all the time. I recommend YLL to everyone I know with notes.”`,

      fName: "Andy, Rouse Land",
      sName: "A",
    },
    {
      title: `"After managing my smaller notes for some time and expanding my portfolio, I urgently needed assistance with amortization schedules. I knew there had to be a more efficient way to administer my notes than the typical Excel spreadsheet. Discovering YourLandLoans.com has been a total game-changer for me as a lender, and for my borrowers. Now, we all feel at ease knowing that we have the exact figures. They can make payments, and I can collect late fees accordingly."`,
      fName: `Manuel, Babylon Homes`,
      sName: "M",
    },
    {
      title: `“I highly recommend YLL! It’s incredibly easy to use and has simplified managing my loans. The platform works flawlessly, and JT and the team provide fantastic support. Truly an awesome tool!”`,
      fName: `Eric, Damen Capital`,
      sName: `E`,
    },
    {
      title: `"YLL has all the features you need to run your note business, and even some you didn't know you needed."`,
      fName: `Rich, Woodward Land Company`,
      sName: `R`,
    },
    {
      title: `"YLL has made communicating with borrowers automatic and I love to see if the borrower has opened or clicked on an email they have been sent. And thanks for your quick responses to inquiries. You guys are on a path to greatness!"`,
      fName: `James, Great Plains Investors`,
      sName: `J`,
    },
    {
      title: `“YLL is not only a great tool for my business but the customer support is excellent. Couldn’t be more pleased with YLL and the team behind it!”`,
      fName: `Braden, Lazy S Land`,
      sName: `B`,
    },
    {
      title: `“YourLandLoan.com is an outstanding service for managing land loans.”`,
      fName: `Seth B.`,
      sName: `SB`,
    },
    {
      title: `"Your Land Loans has made managing notes so easy. Not only for my company, but for my payor. They love the ease and transparency of the platform."`,
      fName: `Danielle, Vista Pointe Properties`,
      sName: `D`,
    },
    {
      title: `"I was always intimidated to self-service notes, Your Land Loans has removed that intimidation factor and helped me get comfortable offering owner financing on more of our properties. I love the product, love the customer service and I’m fired up to keep growing my note portfolio thanks to Your Land Loans."`,
      fName: `Josiah, Acre Vault`,
      sName: `J`,
    },
    {
      title: `"I have been using Your Land Loans for about 6 months now.  Before that, I self-managed my notes by setting up my borrowers on Zelle. Your Land Loans has helped me to simply work on my business instead of in it and focus on growing my real estate portfolio. If you are selling land using owner financing, you have to check this out. This platform is easy to use and flexible enough to work with whatever terms you set up with your borrowers. When I have needed help, they have been quick to respond and offered personalized advice to solve my issue. Great product and great support."`,
      fName: `Alan, CCFI Land`,
      sName: `A`,
    },
    {
      title: `"Your Land Loans has been a stellar tool for our note portfolio. I originally was hesitant in moving everyone over, but the process was super simple with their import feature. There is a level of comfort in knowing your calculations are correct and the ease of reading your reports is just icing on the cake. I've tried and tried to find something wrong with the program, to no avail. If there is a question on something, the support staff has the answer super fast. 10/10 tool for your business."`,
      fName: `Justin, Land Mule`,
      sName: `J`,
    },
  ];

  const feedBackReview = (item, index) => {
    return (
      <Card
        key={index}
        // card-zoom-effect
        className="rounded-3 my-3 shadow card-zoom-effect"
        style={{
          backgroundColor: "white",
          color: "#000",
          cursor: "pointer",
        }}
        onClick={() => setShow({ ...show, item, isOpen: true })}
      >
        <Card.Body className="p-2">
          <div className="d-flex justify-content-start align-items-center mx-2">
            <div
              className="bg-white rounded-circle d-flex justify-content-center align-items-center"
              style={{
                border: "2px solid #008e00",
                height: "52px",
                width: "52px",
                color: "#008e00",
              }}
            >
              <h4 className="fw-700 mb-0">{item.sName}</h4>
            </div>
            <div className="my-3 mx-2 d-flex flex-column justify-content-center">
              <h4
                className="fw-600 text-start"
                style={{ color: "#008e00", lineHeight: 1 }}
              >
                {item?.fName?.split(",")[0]}
              </h4>
              {item?.fName?.split(",")[1] && (
                <p
                  className="fw-600 text-start mb-0"
                  style={{
                    color: "#008e00",
                    fontSize: "17px",
                  }}
                >
                  {item?.fName?.split(",")[1]}
                </p>
              )}
            </div>
          </div>

          <Item item={item} />
        </Card.Body>
      </Card>
    );
  };

  return (
    <>
      <div
        style={{
          background: "url('./images/mynt.jpg')",
          backgroundColor: "#008e00",
          backgroundSize: "cover",
          color: "#fff",
          textAlign: "center",
          padding: "10vh 2vh",
          lineHeight: "0.9",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
        }}
        id="new_insights"
      >
        <div className="new_insights-style">
          <div className="container" style={{ width: "100%" }}>
            <div className="py-4">
              <h1 className="text-center">What our Lenders are Saying</h1>
            </div>
            <Row xs={1} md={2} lg={3} className="my-4">
              <Col className="px-2">
                {data
                  ?.filter((item, index) =>
                    isSmallScreen
                      ? index % 1 === 0
                      : isMediumScreen
                      ? index % 2 === 0
                      : index % 3 === 0
                  )
                  .map((item, i) => {
                    return feedBackReview(item, i);
                  })}
              </Col>
              {isSmallScreen ? null : (
                <Col className="px-2">
                  {data
                    ?.filter((item, index) =>
                      isMediumScreen ? index % 2 === 1 : index % 3 === 1
                    )
                    .map((item, i) => {
                      return feedBackReview(item, i);
                    })}
                </Col>
              )}
              {isMediumScreen || isSmallScreen ? null : (
                <Col className="px-2">
                  {data
                    ?.filter((item, index) => index % 3 === 2)
                    .map((item, i) => {
                      return feedBackReview(item, i);
                    })}
                </Col>
              )}
            </Row>
            <div className="d-flex  justify-content-center">
              <NavLink
                className="btn btn-primary fs-4 fw-bold px-5 mt-5"
                to="Signup"
                style={{ width: "fit-content" }}
              >
                Join 500+ Investors!
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show?.isOpen}
        onHide={() => setShow({ ...show, isOpen: false })}
        centered
        size="lg"
        className="fade"
      >
        <Modal.Header
          closeButton
          style={{ border: "none", alignItems: "start" }}
        >
          <div className="d-flex justify-content-start align-items-center mx-2">
            <div
              className="bg-white rounded-circle d-flex justify-content-center align-items-center"
              style={{
                border: "2px solid #008e00",
                height: "57px",
                width: "57px",
                color: "#008e00",
              }}
            >
              <h3 className="fw-700 mb-0">{show?.item.sName}</h3>
            </div>
            <div className="mx-3 d-flex flex-column justify-content-center">
              <h5
                className="fw-600 text-start"
                style={{ color: "#008e00", lineHeight: 1 }}
              >
                {show?.item?.fName?.split(",")[0]}
              </h5>
              {show?.item?.fName?.split(",")[1] && (
                <p
                  className="fw-600 text-start mb-0"
                  style={{
                    color: "#008e00",
                    fontSize: "16px",
                  }}
                >
                  {show?.item?.fName?.split(",")[1]}
                </p>
              )}
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div
            className="text-muted mb-3"
            style={{
              fontWeight: 400,
              lineHeight: 1.5,
              fontSize: "18px",
              minHeight: `100px`,
            }}
          >
            {show?.item?.title}
          </div>
          {show?.item?.attachedVideo ? show?.item?.attachedVideo : null}
        </Modal.Body>
      </Modal>
    </>
  );
}

function Item({ item }) {
  return (
    <div className="px-1 text-center" style={{ width: "fit-content" }}>
      <p
        className=" text-muted text-start"
        style={{
          fontWeight: 400,
          lineHeight: 1.5,
          fontSize: "18.5px",
          minHeight: "80px",
          color: "#475765",
          margin: "8px 17px",
        }}
      >
        {item?.title}
      </p>
      {item?.attachedVideo ? (
        <div className=""> {item?.attachedVideo}</div>
      ) : null}
    </div>
  );
}
